'use strict';

import store from 'client/store'
import Vue from 'vue'

import blockLib from 'client/lib/block';
import participantLib from 'client/lib/participant'
import sessionLib from 'client/lib/session';

import {min, max, floor, ceil, round, abs} from 'mathjs';
import moment from 'moment';

import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import indexOf from "lodash/indexOf";
import map from "lodash/map";
import range from "lodash/range";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import report from "@/lib/report";
import concat from "lodash/concat";
import _block from "@/lib/block";


const fm = 'HH:mm';

export default {

  updateWarmups: function(session, category = null) {
    const eventDiscipline = find(
      store.state.eventDisciplines.items, item => item.id === session.eventDisciplineId
    );
    const warmups = eventDiscipline.planningConfig && eventDiscipline.planningConfig.warmupConfigs
      ? map(eventDiscipline.planningConfig.warmupConfigs, w => w.name) : [];
    const sessions = store.state.sessions.items;
    let sWu = map(sessions, s => {
      const catIds = map(s.categories, c => c.categoryId);
      if (s.id === session.id) {
        catIds.push(category.id);
      }

      const warmup = s.warmup === undefined ? 99 : indexOf(warmups, s.warmup);
      return {
        id: s.id,
        name: s.name,
        warmup: warmup,
        newWarmup: warmup,
        categories: catIds,
      };
    });

    const categories = store.state.categories.items;
    let cWu = map(categories, c => {
      const sessIds = map(filter(sWu, s => includes(s.categories, c.id)), s => s.id);
      return {
        id: c.id,
        name: c.name,
        warmup: c.warmup === undefined ? 99 : indexOf(warmups, c.warmup),
        sessions: sessIds,
      };
    });

    let changed = true;
    while (changed) {
      // console.log('iterate warmups');
      changed = false;
      // derive category warmups from sessions
      forEach(cWu, c => {
        let w = c.warmup;
        w = reduce(filter(sWu, s => includes(c.sessions, s.id)), (w, s) => {
          console.log('update category warmup', c.name, s.name, w, s.newWarmup);
          return min(w, s.newWarmup);
        }, w);
        c.warmup = w;
      });

      // derive session warmups from categories
      forEach(sWu, s => {
        let w = s.newWarmup;
        // console.log('session warmup', s.name, w);
        w = reduce(filter(cWu, c => includes(s.categories, c.id)), (w, c) => {
          return min(w, c.warmup);
        }, w);
        // console.log('session warmup', s.name, w);
        if (w < s.newWarmup) {
          changed = true;
          s.newWarmup = w;
        }
      });
    }
    // filter sessions to update:
    return map(filter(sWu, s => {
      return s.newWarmup !== s.warmup;
    }), item => {
      return {
        id: item.id,
        name: item.name,
        oldWarmup: warmups[item.warmup],
        newWarmup: warmups[item.newWarmup],
      };
    });
  },

  getEventDays: function (event) {
    const oneDay = 24 * 60 * 60 * 1000;
    const date_from = new Date(event.date_from);
    const date_to = new Date(event.date_to);
    const diffDays = round(abs((date_from - date_to) / oneDay));
    const nextDays = map(Array(diffDays).fill(), (v, i) => {
      const date = new Date(event.date_from);
      date.setDate(date.getDate() + (i + 1));
      return date;
    });
    return [date_from, ...nextDays];
  },

  getSessionsByDate: function (date, sessions) {
    return sortBy(filter(sessions,
      s => new Date(s.date).getTime() === date.getTime()
    ), 'index');
  },

  getPlanningConfig: function(eventDisciplineId) {
    const eventDiscipline = find(
      store.state.eventDisciplines.items, item => item.id === eventDisciplineId
    );
    return eventDiscipline.planningConfig;
  },

  setSessionTimings: function(actual = false) {
    const sessions = store.state.sessions.items;
    if (! sessions.length) {
      return;
    }

    const discipline = store.state.eventDiscipline.discipline;
    const event = store.state.events.current
    const config = this.getPlanningConfig(sessions[0].eventDisciplineId);

    const startingHour = moment(config.dayStartTime ? config.dayStartTime : '09:00', fm);

    const dates = this.getEventDays(event);

    forEach(dates, date => {
      const dateSessions = this.getSessionsByDate(date, sessions);
      let offset = moment(startingHour)
      let first = true;
      forEach(dateSessions, session => {
        const data = cloneDeep(session);
        data.timeTable = []
        delete data._rev;
        const gaps = this.getSessionGaps(data, config, discipline);
        console.log(gaps);
        const warmup = session.warmup ? find(config.warmupConfigs, w => w.name === session.warmup) : null;
        if (! first) {
          offset = offset.add(gaps.preparation.total, 'm');
          offset = this.round5(offset);
        }
        this.addTimeTableItem(data.timeTable, 'competition', offset)

        // set time before competition start
        const judgemeeting = config.judgeMeetingOffset ? moment(offset).add(-gaps.judgeMeeting, 'm') : null;
        this.addTimeTableItem(data.timeTable, 'judgemeeting', judgemeeting)
        const marchin = config.marchinTime ? moment(offset).add(- config.marchinTime,'m') : null;
        this.addTimeTableItem(data.timeTable, 'marchin', marchin)

        // calculate general warm-p gap:
        if (warmup && warmup.generalTime) {
          let warmupGap = warmup.generalTime + gaps.preparation.marchin;
          if (warmup.rotationTime) {
            const multiplier = discipline.rotationType === 'rotation' ? session.rotations : 1
            warmupGap += warmup.rotationTime * multiplier
          }
          const warmupStart = moment(offset).add(- warmupGap, 'm');
          this.addTimeTableItem(data.timeTable, 'warmupStart', warmupStart)

        } else {
          this.addTimeTableItem(data.timeTable, 'warmupStart', null)
        }

        // set time after competition start
        this.calculateCompetitionTimes(data, config, offset, actual)
        offset = this.round5(moment(offset).add(config.ceremonyPrepTime, 'm'));

        this.addTimeTableItem(data.timeTable, 'ceremony', offset)
        offset = moment(offset).add(config.ceremonyTime,'m');
        this.addTimeTableItem(data.timeTable, 'end', offset)

        this.addCustomTimeTableItems(data.timeTable, config)

        store.dispatch("session.save", data)
        first = false;
      });
    });
  },

  addTimeTableItem: function (timeTable, key, time, label = null) {
    timeTable.push({
      key,
      time: time ? time.format(fm) : null,
      label: label ? label : Vue.i18n.t('session.schedule.'+key)
    })
  },

  addCustomTimeTableItems: function(timeTable, config) {
    console.log('add custom times', timeTable , config)
    config.customSessionTimes?.forEach((ci, ci_i) => {
      let base = null
      let direction = 1
      switch (ci.position) {
        case 'before':
          base = timeTable.find(i => i.key === 'competition')
          direction = -1
          break
        case 'after':
          base = timeTable.find(i => i.key === 'end')
          break
      }

      console.log('add custom time item', ci, base)

      if (! base) return

      const time = moment(base.time, fm).add(direction * ci.time, 'm')
      this.addTimeTableItem(timeTable, 'custom-' + ci_i, time, ci.label)
    })
  },

  calculateCompetitionTimes: function(session, config, offset, actual = false) {
    const rt = sessionLib.getSessionRotationTypeFull(session)

    switch (rt) {
      case 'rotation':
        this.calculateRotationCompetitionTimes(session, config, offset, actual)
        break
      case 'block':
        for (let r = 0; r < session.rotations; r++) {
          this.calculateBlockRotationTime(session, config, offset, r, actual)
        }
        break
      case 'mixed':
        this.calculateMixedCompetitionTime(session, config, offset, actual)
        break
    }

  },

  /** Calculate competition times for the rotations in rotation type competition
   * add times for all rotations */
  calculateRotationCompetitionTimes: function(session, config, offset, actual) {
    const warmup = session.warmup ? find(config.warmupConfigs, w => w.name === session.warmup) : null

    let blockSize = 0
    if (actual) {
      blockSize = this.getMaxBlockSize(session, 0, -1)
    } else {
      blockSize = this.estimateBlockSize(session, config)
    }

    let exerciseTime = session.categories.reduce((res, sc) => {
      const et = this.getExerciseTime(config, session.roundIndex, sc.categoryId)
      return Math.max(res, et)
    }, 0)

    if (! exerciseTime) exerciseTime = config.exerciseTime

    exerciseTime += config.judgeTime

    let rotationTime = blockSize * exerciseTime
    if (warmup?.prepTime) {
      let oneTouch = warmup.prepTime
      if (warmup.prepPerCompetitor) {
        oneTouch *= blockSize
      }
      rotationTime += oneTouch
    }

    // add rotation start times to the session time table
    forEach(session.rotationsSet, (rs, ind) => {
      this.addRotationTimeTableItem(session, ind, 'start', offset)
      offset.add(rotationTime, 's')
      offset.add(config.rotationTime, 'm')
    })
  },

  /** Calculate the time for one single rotation in a block competition type */
  calculateBlockRotationTime: function(session, config, offset, rotation, actual) {
    const warmup = session.warmup ? find(config.warmupConfigs, w => w.name === session.warmup) : null

    let blockTime = 0

    if (config.blockTimeType === 'fixed') {
      blockTime = config.blockTime * 60
      if (rotation === 0) {
        blockTime += config.blockTimeFirst * 60
      }
    } else {
      if (actual) {
        blockTime = this.getActualBlockRotationTime(session, config, rotation)
      } else {
        const setInfo = this.getSessionSets(session, config);
        const blockSize = this.estimateBlockSize(session, config)
        const exerciseCount = reduce(setInfo, (count, set) => max(count, set.exercises), 0)
        const exerciseTime = reduce(setInfo, (time, set) => max(time, set.exerciseTime), 0)
        blockTime = blockSize * (exerciseTime + (config.judgeTime * exerciseCount))
      }
    }

    if (warmup?.rotationTime) {
      let warmupTime = moment(offset)
      if (warmup.rotationTimeOverlap || rotation === 0) {
        warmupTime = moment(offset).add(-warmup.rotationTime, 'm')
      } else {
        offset.add(warmup.rotationTime, 'm')
      }
      this.addRotationTimeTableItem(session, rotation, 'warmup', warmupTime)
    }
    this.addRotationTimeTableItem(session, rotation, 'start', offset)
    offset.add(blockTime, 's')
    offset.add(config.rotationTime, 'm')
  },

  calculateMixedCompetitionTime: function(session, config, offset, actual) {
    console.log('calculate mixed competition time')
    const warmup = session.warmup ? find(config.warmupConfigs, w => w.name === session.warmup) : null;

    let rotationTime = 0
    if (! actual) {
      const panelExercises = this.getPanelExercises(session, config);

      let exerciseTime = reduce(panelExercises, (sum, pe) => {
        return sum + pe.time;
      }, 0);

      switch(session.sets) {
        case 1:
          exerciseTime += config.judgeTime * reduce(panelExercises, (sum, pe) => {
            return sum + pe.participations;
          }, 0);
          break;
        case 2: {
          const c1 = panelExercises[0].participations;
          const c2 = panelExercises[1].participations;
          const diff = abs(c1 - c2);
          exerciseTime += config.judgeTime * diff;
        }
      }

      rotationTime = ceil(exerciseTime / session.rotations);
    }

    for(let r = 0; r < session.rotations; r++) {
      if (warmup?.rotationTime) {
        let warmupTime = moment(offset)
        if (warmup?.rotationTimeOverlap || r === 0) {
          warmupTime = moment(offset).add(-warmup?.rotationTime, 'm');
        } else {
          offset.add(warmup?.rotationTime, 'm');
        }
        this.addRotationTimeTableItem(session, r, 'warmup', warmupTime)
      }
      this.addRotationTimeTableItem(session, r, 'start', offset)

      if (actual) {
        // override rotation time based on actual exercises
        rotationTime = this.getActualMixedBlockTime(session, config, r)
      }

      offset.add(rotationTime, 's');
      offset.add(config.rotationTime, 'm');
    }
  },

  getMaxBlockSize: function(session, set, rotation) {
    let blocks = store.state.blocks.items.filter(b => b.sessionId === session.id)
    if (set > 0) {
      blocks = blocks.filter(b => b.set === set)
    }

    if (rotation >= 0) {
      blocks = blocks.filter(b => b.index === rotation)
    }

    return reduce(blocks, (size, block) => {
      const bps = store.state.blockParticipations.items.filter(bp => bp.blockId === block.id)
      return max(size, bps.length)
    }, 0)
  },

  estimateBlockSize: function(session, config) {
    const setInfo = this.getSessionSets(session, config);
    return reduce(setInfo, (size, set) => {
      const count = ceil(set.totalParticipations / set.rotations);
      return max(size, count);
    }, 0);
  },

  getActualBlockRotationTime: function(session, config, rotation) {
    const blocks = store.state.blocks.items.filter(b => (b.sessionId === session.id && b.index === rotation))

    let rotationTime = 0;

    blocks.forEach(block => {
      let blockTime = 0;
      const bps = sortBy(store.state.blockParticipations.items.filter(bp => bp.blockId === block.id), 'index')

      let participantTime = 0
      bps.forEach(bp => {
        if (! bp.filler) {
          const part = participantLib.getParticipation(bp.participationId)
          const formatExercises = sessionLib.getSessionCategoryExerciseTypes(session.id, part.categoryId)
          const exerciseTime = reduce(formatExercises, (sum, exerciseTypeId) => {
            const et = this.getExerciseTime(config, session.roundIndex, part.categoryId, exerciseTypeId)
            let res = sum
            if (et) {
              res += et
            }
            return res
          }, 0)

          participantTime = exerciseTime + formatExercises.length * config.judgeTime
        }

        blockTime += participantTime
      })

      rotationTime = max(rotationTime, blockTime)
    })

    return rotationTime
  },

  getActualMixedBlockTime: function(session, config, rotation) {
    const block = store.state.blocks.items.find(b => (b.sessionId === session.id && b.index === rotation))

    if (! block) return 0

    const bps = sortBy(store.state.blockParticipations.items.filter(bp => bp.blockId === block.id), 'index')

    let rotationTime = 0

    let prevPanel = 0
    bps.forEach(bp => {
      const part = participantLib.getParticipation(bp.participationId)
      const exerciseTime = this.getExerciseTime(config, session.roundIndex, part.categoryId, bp.exerciseTypeId)
      const panel = blockLib.getMixedOrderPanel(bp, part, session)

      rotationTime += exerciseTime
      if (panel === prevPanel) {
        rotationTime += config.judgeTime
      }
      prevPanel = panel
    })

    return rotationTime
  },

  getBlockLabel: function(rotationType, sets, rotation, type) {
    let label = Vue.i18n.t('rotation') + ' ' + (rotation+1)
    switch (rotationType) {
      case 'block':
        if (sets === 1) {
          label = Vue.i18n.t('block') + ' ' + (rotation+1)
        } else {
          const blocks = map(Array(sets).fill(), (v, i) => i + 1 + rotation * sets)
          label = Vue.i18n.t('blocks') + ' ' + blocks.join(', ')
        }
        break
      case 'mixed':
        label = Vue.i18n.t('block') + ' ' + (rotation+1)
        break
    }

    if (type === 'warmup') {
      label += ' ' + Vue.i18n.t('warmup')
    } else if (rotationType === 'block') {
      label += ' ' + Vue.i18n.t('competition')
    }

    return label
  },

  addRotationTimeTableItem(session, rotation, type, time) {
    const rotationType = sessionLib.getSessionRotationTypeFull(session)

    session.timeTable.push({
      key: 'rotation-' + type + '-' + rotation,
      time: time ? time.format(fm) : null,
      label: this.getBlockLabel(rotationType, session.sets, rotation, type),
      rotation,
      type,
    })
  },

  round5: function(m) {
    if (m.minutes() % 5 !== 5) {
      let intervals = floor(m.minutes() / 5) + 1;
      if (intervals === 12) {
        m.add(1, 'h');
        intervals = 0;
      }
      m.minutes(intervals * 5);
      m.seconds(0);
      return m;
    }
  },

  getSessionGaps: function(session, config, discipline) {
    // console.log('gaps', session, config);
    let meeting = 0;
    let prepMarchin = 0;
    let prepGeneralWarmUp = 0;
    let prepBlockWarmup = 0;
    if (config) {
      meeting += config.judgeMeetingOffset;

      if (config.marchinPrepTime) {
        prepMarchin += config.marchinPrepTime;
      }
      if (config.marchinTime) {
        prepMarchin += config.marchinTime;
      }

      const warmup = session.warmup ? find(config.warmupConfigs, w => w.name === session.warmup) : null;
      if (warmup && warmup.type === 'regular') {
        if (warmup.generalTime && ! warmup.generalTimeOverlap) {
          prepGeneralWarmUp += warmup.generalTime;
        }
        if (warmup.rotationTime && ! warmup.rotationTimeOverlap) {
          console.log('add rotation warmup time');
          let multiplier = 1;
          if (discipline.rotationType === 'rotation') {
            multiplier = reduce(session.setProperties, (r, p) => max(p.rotations, r), multiplier);
          }
          prepBlockWarmup += multiplier * warmup.rotationTime;
        }
      }
    }

    return {
      judgeMeeting: meeting,
      preparation: {
        total: prepMarchin + prepGeneralWarmUp + prepBlockWarmup,
        marchin: prepMarchin,
        general: prepGeneralWarmUp,
        block: prepBlockWarmup,
      },
    };
  },

  getSessionSets: function (session, config) {
    let totalParticipations = 0;
    forEach(session.categories, sCategory => {
      if (sCategory.set <= session.sets) {
        const categoryCount = this.getSessionCategoryParticipationsLength(
          sCategory
        );
        totalParticipations += categoryCount;
      }
    });

    const sets = map(Array(session.sets).fill(), (v, i) => {
      const setNum = i + 1;

      // Get sessionCategories
      let categories = filter(
        session.categories,
        category => category.set === setNum
      );
      categories = sortBy(categories, 'subDivision');

      // Calculate total participations on set
      let setParticipations = 0;

      if (session.rotationFree) {
        setParticipations = Math.ceil(totalParticipations / session.sets)
      } else {
        forEach(categories, category => {
          const categoryCount = this.getSessionCategoryParticipationsLength(
            category
          );
          setParticipations += categoryCount;
        });
      }

      // rotations on set (fallback use sessions rotations when undefined)
      const setProperties =
        session.setProperties &&
        find(session.setProperties, sp => sp.set === setNum);
      let rotations = session.rotations;
      if (setProperties) {
        rotations = setProperties.rotations;
      }

      // Add some extra info about the category
      let exercises = 0
      const fCategories = []
      let exerciseTime = 0
      forEach(categories, category => {
        const info = find(store.state.categories.items, c => c.id === category.categoryId);
        // filter old subdivisions when lower then max category.subDivisions
        let nCategory = clone(category);
        nCategory.regions = map(category.regions, r => find(config.regions, r2 => r2.id === r));
        if (info.rounds[0].subDivisions >= nCategory.subDivision) {
          fCategories.push({...nCategory, info});
        }
        const formatExercises = sessionLib.getSessionCategoryExerciseTypes(session.id, category.categoryId)
        const catExerciseTime = reduce(formatExercises, (sum, exerciseTypeId) => {
          const et = this.getExerciseTime(config, session.roundIndex, category.categoryId, exerciseTypeId)
          let res = sum;
          if (et) {
            res += et
          }
          return res
        }, 0)
        exerciseTime = max(exerciseTime, catExerciseTime)

        exercises = max(exercises, formatExercises.length);
      });
      return {
        id: setNum,
        categories: fCategories,
        totalParticipations: round(setParticipations),
        rotations,
        exercises,
        exerciseTime
      };
    });
    return sets;
  },

  getSessionCategoryParticipationsLength: function (sessionCategory) {
    const originCategory = find(
      store.state.categories.items,
      c => c.id === sessionCategory.categoryId
    );
    const participations = filter(
      store.state.participations.items,
      p => p.categoryId === sessionCategory.categoryId && p.participantType !== 'team'
    );
    const subDivisions = originCategory.rounds[0].subDivisions;
    if (
      sessionCategory.subDivision <= originCategory.rounds[0].subDivisions
    ) {
      return participations.length / subDivisions;
    }
    return 0;
  },

  getPanelExercises: function (session, config) {
    const panels = range(1, parseInt(session.sets) + 1);
    const dataPanels = [];
    forEach(panels, p => {
      let panelParticipations = 0;
      let exerciseTime = 0;
      forEach(session.categories, sc => {
        const participations = this.getSessionCategoryParticipationsLength(sc);
        const exercises = filter(sc.exercises, e => e.set === p);
        exerciseTime += reduce(exercises, (sum, exercise) => {
          const time = this.getExerciseTime(config, session.roundIndex, sc.categoryId, exercise.exerciseTypeId)
          return sum + participations * time;
        }, 0);
        panelParticipations += round(participations * exercises.length);
      });
      dataPanels.push({id: p, participations: panelParticipations, time: exerciseTime});
    });
    return dataPanels;
  },

  getExerciseTime: function(config, roundIndex, categoryId, exerciseTypeId) {
    const category = store.state.categories.items.find(c => c.id === categoryId)
    const catRound = category.rounds.find(r => r.roundIndex === roundIndex)

    let exerciseTime = config.exerciseTime

    if (catRound.exerciseTime) {
      exerciseTime = catRound.exerciseTime
    }

    if (catRound?.exerciseTiming && exerciseTypeId) {
      const te = find(catRound.exerciseTiming, et => et.type === exerciseTypeId)
      if (te?.time) {
        exerciseTime = te.time
      }
    }

    return exerciseTime
  },

  /**
   * Get a list of used bib numbers either for regular (non team) participations
   */
  filterNumbers: function(participations) {
    const numbersUsed = []
    participations = filter(participations, part => {
      if (part.bib) {
        numbersUsed.push(part.bib);
        return false
      }
      return true
    })

    return [
      participations,
      numbersUsed,
    ]

  },

  bibAssignmentTeam: function(session, context, set) {
    let teams = []
    let teamOffset = Math.ceil(context.bibOffset / 10)
    console.log(session.name, context.bibOffset, teamOffset)

    const blocks = sortBy(filter(
      store.state.blocks.items, block => block.sessionId === session.id && block.set === set), 'index')

    forEach(blocks, block => {
      const blockParticipations = blockLib.getBlockParticipations(block.id);

      forEach(blockParticipations, blockPart => {
        if (! blockPart.participationId) return

        let part = participantLib.getParticipation(blockPart.participationId)
        let teamPart = participantLib.getTeamParticipation(part)

        if (! teamPart) {
          teamPart = {
            id: part.id,
            noTeam: true
          }
        }

        let team = teams.find(t => t.teamPart.id === teamPart.id)
        if (! team) {
          team = {
            teamPart,
            bib: ++teamOffset,
            memberParts: []
          }
          teams.push(team)
        }

        team.memberParts.push(part)
      });
    });

    teamOffset += context.spacing
    console.log(session.name + ' 1', teamOffset)

    // console.log(teams)
    teams.forEach(team => {
      if (! team.teamPart.noTeam) {
        this.updateBib(team.teamPart.id, team.bib)
      }

      let memberBib = team.bib * 10
      team.memberParts.forEach(memberPart => {
        this.updateBib(memberPart.id, ++memberBib)
      })
      context.bibOffset = Math.max(memberBib, context.bibOffset)
    })
    console.log(session.name + ' 2', context.bibOffset)
  },

  bibAssignmentBlockMixed: function(session, context) {
    console.log('assign mixed simulation bib numbers')
    // assign numbers according to mixed order simulation
    const sets = session.sets
    let bibOffset = Math.ceil((context.bibOffset - 0.00001) / sets) * sets
    let newBibOffset = bibOffset
    console.log('offsets', sets, bibOffset, newBibOffset)
    for(let s = 1; s <= sets; s++) {
      const blocks = sortBy(filter(
        store.state.blocks.items, block => block.sessionId === session.id && block.set === s), 'index')

      let blockOffset = bibOffset - sets + s
      forEach(blocks, block => {
        const blockParticipations = blockLib.getBlockParticipations(block.id);
        forEach(blockParticipations, blockPart => {
          let part = find(context.participations, item => item.id === blockPart.participationId);
          while (part) {
            blockOffset += sets
            if (! includes(context.numbersUsed, blockOffset)) {
              this.updateBib(part.id, blockOffset)
              context.participations = filter(context.participations, item => item.id !== part.id)
              part = null
              context.numbersUsed.push(blockOffset)
            }
          }
        })
      })
      newBibOffset = Math.max(newBibOffset, blockOffset)
    }

    // add gap:
    for(let i = 0; i < context.spacing; i++) {
      while (includes(newBibOffset, ++newBibOffset)) {
        context.bibOffset = newBibOffset + 0
      }
    }
    context.bibOffset = newBibOffset
  },

  bibAssignmentRegular: function(session, context, set) {
    let bibOffset = context.bibOffset

    const blocks = sortBy(filter(
      store.state.blocks.items, block => block.sessionId === session.id && block.set === set), 'index')

    forEach(blocks, block => {
      const blockParticipations = blockLib.getBlockParticipations(block.id)

      forEach(blockParticipations, blockPart => {
        let part = find(context.participations, item => item.id === blockPart.participationId)
        while (part) {
          if (! includes(context.numbersUsed, ++bibOffset)) {
            this.updateBib(part.id, bibOffset)
            context.participations = filter(context.participations, item => item.id !== part.id)
            part = null
            context.numbersUsed.push(bibOffset)
          }
        }
      })
    })

    // add gap:
    for(let i = 0; i < context.spacing; i++) {
      while (includes(context.numbersUsed, ++bibOffset)) {
        bibOffset = bibOffset + 0
      }
    }
    context.bibOffset = bibOffset
  },

  bibAssignment: function (spacing, keepNumbers, teamSessions) {
    console.log('bib assignments')
    console.log(spacing, teamSessions)
    let numbersUsed = [];
    let participations = store.state.participations.items.filter(p => p.participantType !== 'team')

    if (keepNumbers) {
      [participations, numbersUsed] = this.filterNumbers(participations)
    }

    let context = {
      bibOffset: 0,
      teamOffset: 0,
      numbersUsed,
      participations,
      spacing,
    }

    const sessions = sortBy(store.state.sessions.items, 'index')
    forEach(sessions, session => {
      const secondaryType = sessionLib.getSessionRotationTypeSecondary(session)

      if (secondaryType === 'block') {
        return
      }

      if (secondaryType === 'mixed') {
        this.bibAssignmentBlockMixed(session, context)
      } else {
        for (let set = 1; set <= session.sets; set++) {
          if (teamSessions[session.id][set-1]) {
            this.bibAssignmentTeam(session, context, set)
          } else {
            this.bibAssignmentRegular(session, context, set)
          }
        }
      }
    })
  },

  updateBib: function(id, bib) {
    const data = { id, bib }
    store.dispatch('participation.save', { data: data })
  },

  printPlanning: function(event, eventDiscipline, config, includeTimes) {
    const sections = [];
    let regions = map(config.regions, r => r);
    regions.push({id: 'other', name: Vue.i18n.t('Other')});
    const sessions = store.state.sessions.items

    const dates = this.getEventDays(event);
    let first = true;
    forEach(dates, date => {
      const dateSessions = this.getSessionsByDate(date, sessions);
      forEach(dateSessions, session => {
        const times = sessionLib.getSessionTimes(session);
        const rotationType = sessionLib.getSessionRotationType(session)

        let section = {
          title: moment(date).format("dddd DD-MM-YYYY") + ' - ' + session.name,
          type: 'planning',
          fullWidth: rotationType === 'mixed',
          data: {
            timing: includeTimes ? times : null,
          },
        };

        section.data.sets = map(filter(this.getSessionSets(session, config), item => item.categories.length > 0), set => {
          const categories = map(set.categories, setCategory => {
            return {
              name: setCategory.info.name,
              subDivision: setCategory.subDivision,
              total: Math.ceil(this.getSessionCategoryParticipationsLength(setCategory)),
              regions: map(setCategory.regions, r => r.name),
            }
          })
          return {
            set: set.id,
            rotations: set.rotations,
            total: set.totalParticipations,
            categories: categories,
          }
        })

        if (! first) {
          sections.push({
            type: 'pagebreak',
          })
        }
        first = false

        sections.push(section)
      })
    })

    report.publishReport(eventDiscipline, sections, 'planning');
  },

  getStartingOrderSections: function(sessions, forExport = false) {
    return new Promise((resolve, reject) => {
      Promise.all(sessions.map(session => _block.preparePrint(session, forExport))).then(results => {
        resolve(concat(...results))
      }, err => reject(err))
    })
  },

  printStartingOrder: function(eventDiscipline, sessions) {
    this.getStartingOrderSections(sessions).then(sections => {
      sections.pop()
      report.publishReport(eventDiscipline, sections, 'order');
    })
  }
};
